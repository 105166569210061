<template>
  <div class="dashboard">
    <div class="dashboard__row">
      <div class="controls">
        <div class="controls__col controls__col_narrow stats">
          <Header>
            Company Name
            <template slot="description">
              Welcome to your <br>
              personal account!
            </template>
          </Header>
          <div class="stats__total">
            {{totalOrders}}
          </div>
          <p class="text-center stats__desc">Total delivered orders</p>
          <router-link :to="{name: 'Account'}" class="button button_outline">My Account</router-link>

        </div>
        <div class="controls__col form">
          <Header>
            Download Order Form
            <template slot="description">
              To add new orders, please <br>
              download and fill out the form
            </template>
          </Header>

          <div class="form__icon"></div>
          <p class="text-center form__desc">Please fill in all data carefully</p>
          <a href="/orders.xlsx" download="" class="button">Download</a>

        </div>
        <div class="controls__col upload">
          <Header>
            Upload New Orders
            <template slot="description">
              To add new orders please upload <br>
              the completed form
            </template>
          </Header>

          <div class="upload__drop"></div>
          <p class="text-center upload__desc">Please fill in all data carefully</p>
          <a href="#" class="button">Send File</a>
        </div>
      </div>
    </div>
    <div class="dashboard__row">
      <Statistics/>
    </div>
    <div class="dashboard__row">
      <OrdersPreview/>
    </div>

  </div>
</template>
<script>
import OrdersPreview from "../components/OrdersPreview";
import Statistics from "../components/Statistics";
import Header from "../components/Header";
import {mapState} from "vuex";

export default {
  components: {Statistics, Header, OrdersPreview},
  computed:{
    ...mapState('dashboard', ['totalOrders'])
  }

}
</script>
<style lang="scss" scoped>
.upload {
  &__desc {
    margin-bottom: 40px;
  }

  &__drop {
    background-color: #E9ECFF;
    border: 3px dashed #969BA0;
    height: 200px;
    width: 100%;
    border-radius: 22px;
    margin-top: 50px;
    margin-bottom: 32px;
  }
}

.form {
  &__desc {
    margin-bottom: 40px;
  }

  &__icon {
    background-image: url("../assets/excel.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 200px;
    height: 200px;
    margin-top: 50px;
    margin-bottom: 32px;
  }
}

.stats {
  &__desc {
    margin-bottom: 40px;
  }

  &__total {
    background-image: url("../assets/Ellipse.svg");
    background-size: contain;
    text-align: center;
    color: #FF720D;
    font-size: 65px;
    line-height: 200px;
    width: 200px;
    height: 200px;
    margin-top: 50px;
    margin-bottom: 32px;
  }
}


.controls {
  display: flex;
  width: 100%;

  &__col {
    &_narrow {
      flex: 2
    }

    padding: 30px;
    flex: 3;
    background: white;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dashboard {
  display: flex;
  flex-direction: column;

  &__row {
    margin-bottom: 40px;
  }
}


</style>
