<template>
  <div class="user">
    <div class="user__name">
      {{ currentUser.name }}
<!--      <button @click="logout">Logout</button>-->
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  computed:{
    ...mapState('auth', ['currentUser'])
  }
}
</script>

<style lang="scss" scoped>
.user {

  background-image: url("../assets/arrow_down.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  padding-right: 40px;
  cursor: pointer;
  margin: 20px;
  &__name {
    font-weight: 500;
    font-size: 20px;
  }
}


</style>
