import api from "../plugins/axios";
import router from '../router'

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    currentUser: {}
  },
  mutations: {
    setLoggedIn(state, data) {
      state.currentUser = data
      state.loggedIn = true
    },
    setLoggedOut(state) {
      state.currentUser = {}
      state.loggedIn = false
      localStorage.removeItem('freecoma_auth')
    }
  },
  actions: {
    async init({state, commit}) {
      if (state.loggedIn) {
        return true
      } else {
        try {
          let resp = await api.get('me')
          commit('setLoggedIn', resp.data)
        } catch (e) {
          commit('setLoggedOut')
          throw 'Unauthorized'
        }
      }
    },
    async login({commit, dispatch}, {creds, token}) {
      let resp = await api.post('sign_in', {client_user: creds})
      localStorage.setItem('freecoma_auth', resp.headers.authorization)
      commit('setLoggedIn', resp.data)
      await dispatch('init', {}, {root: true})
      router.push({name: 'Dashboard'})
    },
    async logout({commit}) {
      await api.delete('sign_out')
      commit('setLoggedOut')
      router.push({name: 'Login'})
    }
  }
}
