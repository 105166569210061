import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import dashboard from './dashboard'
import orders from './orders'
import api from "../plugins/axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    statusGroups: null,
    statuses: {},
  },
  mutations: {
  },
  actions: {
    async init({state}){
      const resp = await api.get('order_status_groups')
      state.statusGroups = resp.data.order_status_groups
      state.statusGroups.map((s)=>{
        s.statuses.forEach((statusID) =>{
          state.statuses[statusID] = {
            color: s.color,
            name: s.name
          }
        })
      })
    }
  },
  modules: {
    auth, dashboard, orders
  }
})
