import api from "../plugins/axios";

export default {
  namespaced: true,
  state: {
    filters: {
      status: null
    },
    orders: null,
    meta: null,
    // statusGroups: null,
    // statuses: {}
  },
  mutations: {},
  actions: {
    async loadOrders({state}) {
      let resp = await api.get('orders',  {params: state.filters})
      state.orders = resp.data.orders
      state.meta = resp.data.meta
    },
    async filterByStatus({state, dispatch}, data) {
      state.filters.status = data
      await dispatch('loadOrders')
    },
    async init({state}) {
      let resp = await api.get('orders')
      state.orders = resp.data.orders
      state.meta = resp.data.meta
      // resp = await api.get('order_status_groups')
      // state.statusGroups = resp.data.order_status_groups
      // state.statusGroups.map((s) => {
      //   s.statuses.forEach((statusID) => {
      //     state.statuses[statusID] = {
      //       color: s.color,
      //       name: s.name
      //     }
      //   })
      // })
    }
  },
}

