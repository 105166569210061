<template>
  <div class="header">
    <div class="header__title">
      <slot/>
    </div>
    <div class="header__desc">
      <slot name="description"/>
    </div>
  </div>

</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped lang="scss">
.header {
  border-left: 9px solid #FF720D;
  padding-left: 14px;
  width: 100%;



  &__title {
    font-size: 20px;
    line-height: 30px;

  }

  &__desc {
    font-size: 12px;
    line-height: 18px;
    color: #969BA0;
  }
}

</style>
