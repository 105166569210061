<template>
  <div class="track" :class="{track_visible: visible}" v-click-outside="hide">
    <div class="track__name">Track Number</div>
    <div class="track__track">{{ track }}
      <a href="#" @click.prevent="" v-clipboard="track" class="track__cb">Copy to clipboard</a></div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    track: {
      type: String
    },
    visible: {
      type: Boolean
    }
  },
  methods: {
    hide() {
      if (this.visible)
        this.$emit('hidden')
    }

  }
  ,
  directives: {
    ClickOutside
  }
}
</script>

<style scoped lang="scss">
.track {
  background: #FFFFFF;
  box-shadow: 0px 10px 49px rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  font-size: 16px;
  padding: 30px 40px;
  text-align: left;
  display: none;

  &_visible {
    display: block;
  }

  &__cb {
    margin-left: 10px;
    font-size: 14px;
    color: #969BA0;
  }

  &__track {
    color: #242424;
  }

  &__name {
    color: #2F4CDD;
    margin-bottom: 6px;
  }
}
</style>
