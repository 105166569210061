<template>
  <div class="stats">
    <div class="stats__header">
      <Header>
        Statistics
        <template slot="description">
          Order statistics by statuses
        </template>
      </Header>
      <div class="stats__controls">
        <div class="btn__group">
          <div class="button button_outline button_small"
               @click="loadStats('all')"
               :class="{button_active:statsFilter=='all'} "
          >All
          </div>
          <div class="button button_outline button_small"
               :class="{button_active:statsFilter=='month'} "
               @click="loadStats('month')">Month
          </div>
          <div class="button button_outline button_small"
               :class="{button_active:statsFilter=='10days'} "
               @click="loadStats('10days')">10 days
          </div>
        </div>
      </div>
    </div>

    <div class="stats__orders">
      <div class="stats__cols" v-if="statistics">

        <div class="stats__col col" v-for="s in stats.stats"
             :style="{height: ( s[1] / stats.max * 90) + '%',
             color: statusGroup(s[0]).color,
             'border-bottom-color': statusGroup(s[0]).color,
             'border-bottom-color': statusGroup(s[0]).color,
             'background-color': hexToRgb(statusGroup(s[0]).color)
        }"
        >
          <div class="col__percent">
            {{ s[2] }}%
          </div>
          <div class="col__name">
            {{ statusGroup(s[0]).name }}
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import {mapState, mapActions} from 'vuex'

export default {
  components: {Header},
  props: {},
  methods: {
    ...mapActions('dashboard', ['loadStats']),
    statusGroup(id) {
      const index = this.statusGroups.findIndex(s => s.id.toString() == id.toString())
      return index > -1 ? this.statusGroups[index] : {}
    },
    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      let rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      return `rgba(${rgb.r},${rgb.g},${rgb.b},0.2)`
    }
  },
  computed: {
    ...mapState('dashboard', ['statistics', 'statsFilter']),
    ...mapState(['statusGroups', 'statuses']),
    stats() {
      let total = 0
      let max = 1
      this.statistics.map((c) => {
        total += c[1]
        max = max < c[1] ? c[1] : max
      })
      this.statistics.map(c => c[2] = Math.round(total > 0 ? c[1] / total * 100 : 0))
      console.log(this.statistics)
      return {
        total: total,
        max: max,
        stats: this.statistics
      }
    }
  },
  data() {
    return {
      filter: '10days',
    }
  }

}
</script>


<style scoped lang="scss">
.col {
  &__percent {
    font-weight: 500;
    font-size: 40px;
    top: -50px;
    position: relative;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    bottom: -60px;
    text-align: center;
    width: 100%;
    position: absolute;
    text-transform: uppercase;
  }
}

.stats {
  background: white;
  padding: 30px 30px 70px 30px;

  &__orders {
    //display: flex;
    //align-items: flex-end;
    //height: 100px;
    height: 330px;
    //padding-top: 30px;
  }

  &__cols {
    background-image: url("../assets/lines.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 100%;
    display: flex;
    align-items: flex-end;
    height: 280px;
    padding-bottom: 1px;
  }

  &__col {
    flex: 1;
    text-align: center;
    color: #2F4CDD;
    border-bottom: 10px solid #2F4CDD;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: .1;
      //z-index: -1;
      //background: #2F4CDD;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
}
</style>
