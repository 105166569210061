<template>
  <div class="stats" v-if="orders">
    <div class="stats__header">
      <Header>
        Recent Order Request
        <template slot="description">
          Orders with recently changed statuses
        </template>
      </Header>
      <div class="stats__controls">
        <router-link class="button button_outline button_small" :to="{name: 'Orders'}">All Orders <img
            src="@/assets/Vector.png">
        </router-link>
      </div>
    </div>
    <div class="stats__orders">
      <table>
        <thead>
        <tr>
          <th>Order ID</th>
          <th>Created</th>
          <th class="text-left">Product</th>
          <th>Price</th>
          <th class="text-left">Client</th>
          <th class="text-left">City</th>
          <th>Order Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="order in orders">
          <td style="width: 160px">#{{order.id}}</td>
          <td style="width: 150px">{{order.created_at | date}}</td>
          <td class="text-left">{{order.product_name}}</td>
          <td>{{order.product_price}}</td>
          <td class="text-left">{{currentUser.name}}</td>
          <td class="text-left">{{order.city ? order.city.name : order.city_name}}</td>
          <td>
            <button class="status" disabled :style="{background: statuses[order.order_status_id].color}">
              {{ statuses[order.order_status_id].name }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import Header from "../components/Header";
import {mapState} from 'vuex'
export default {
  components: {Header},
  props: {},
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('orders', ['orders']),
    ...mapState(['statuses']),
    stats() {
      let total = 0
      let max = 0
      this.orders.map((c) => {
        total += c[1]
        max = max < c[1] ? c[1] : max
      })
      this.orders.map(c => c[2] = Math.round(c[1] / total * 100))
      return {
        total: total,
        max: max,
        stats: this.orders
      }
    }
  },
  data() {
    return {
      filter: '10days',
    }
  }

}
</script>


<style scoped lang="scss">
table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 12px 33px rgba(62, 73, 84, 0.08);
  th {
    background: #FF720D;
    color: white;
    height: 70px;
    text-align: center;
    font-size: 18px;
    &.text-left{
      text-align: left;
    }
  }
  td{
    height: 80px;
    font-size: 16px;
    color: #242424;
    text-align: center;
    &.text-left{
      text-align: left;
    }
  }
}

.stats {
  background: white;
  padding: 30px;

  &__orders {
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
}
</style>
