import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

require('./plugins/axios.js')
Vue.config.productionTip = false
import './css/button.scss'
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)
Vue.filter('date', function (value) {
  if (!value) return ''
  value = new Date(value.toString())
  return value.toLocaleString()
})

const init = async function () {
  try {
    await store.dispatch('auth/init')
    await store.dispatch('init')
  } catch (e) {
  } finally {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')

  }
}
init()
