<template>
  <div class="orders">
    <div class="orders__row">
      <div class="filter">
        <div class="filter__controls">
          <div class="btn__group">
            <div class="button button_outline button_small"
                 @click="filterByStatus(null)"
                 :class="{button_active:filters.status==null} "
            >All
            </div>
            <div class="button button_outline button_small"
                 v-for="s in statusGroups"
                 :class="{button_active:filters.status==s.id} "
                 @click="filterByStatus(s.id)">{{ s.name }}
            </div>
          </div>
        </div>
        <div class="filter__export">
        </div>
      </div>
    </div>
    <div class="orders__row">
      <table>
        <thead>
        <tr>
          <th style="width: 160px">Order ID</th>
          <th style="width: 150px">Created</th>
          <th class="text-left">Product</th>
          <th>Price</th>
          <th class="text-left">Client</th>
          <th class="text-left">City</th>
          <th>Order Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="order in orders">
          <td>#{{ order.id }}</td>
          <td>{{ order.created_at | date }}</td>
          <td class="text-left">{{ order.product_name }}</td>
          <td>{{ order.product_price }}</td>
          <td class="text-left">{{ currentUser.name }}</td>
          <td class="text-left">{{ order.city ? order.city.name : order.city_name }}</td>
          <td>
            <button class="status" disabled :style="{background: statuses[order.order_status_id].color}">
              {{ statuses[order.order_status_id].name }}
            </button>
          </td>
          <td>
            <div class="track">
              <TrackNum
                  :visible="track==order.id"
                  @hidden="track=null"
                  :track="order.code128" class="track__num"/>
              <button class="button button_outline button_small"
                      @click.stop="track=order.id"
              >...
              </button>
            </div>


          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="orders__row">
      <div class="pagination">
        <div class="pagination__desc">
          Showing <b>{{ orders.length }}</b> from <b> {{ meta.total_count }} </b>orders
        </div>
        <div class="pagination__controls">
          <button>1</button>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapState, mapActions} from "vuex";
import TrackNum from "../components/TrackNum";

export default {
  components: {TrackNum},
  data() {
    return {
      track: null
    }
  },
  methods: {
    ...mapActions('orders', ['filterByStatus'])
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState(['statusGroups', 'statuses']),
    ...mapState('orders', ['orders', 'filters', 'meta']),
  }
}
</script>
<style scoped lang="scss">
.track {
  position: relative;

  &__num {

    position: absolute;
    left: -200px;
    top: 0px;
    z-index: 10;
  }

  &_visible {
    .track__num {
      display: block;
    }
  }
}

.filter {
  display: flex;
  justify-content: space-between;

  &__controls {

  }
}

.pagination {
  display: flex;
  justify-content: space-between;

  &__desc {
    font-size: 16px;
  }

  &__controls {

  }
}

.orders {
  display: flex;
  flex-direction: column;

  &__row {
    margin-bottom: 40px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0px 12px 33px rgba(62, 73, 84, 0.08);

  th {
    background: #FF720D;
    color: white;
    height: 70px;
    text-align: center;
    font-size: 18px;

    &.text-left {
      text-align: left;
    }
  }

  td {
    height: 80px;
    font-size: 16px;
    color: #242424;
    text-align: center;

    &.text-left {
      text-align: left;
    }
  }
}

.stats {
  background: white;
  padding: 30px;

  &__orders {
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
}
</style>
