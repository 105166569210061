import api from "../plugins/axios";

export default {
  namespaced: true,
  state: {
    totalOrders: null,
    statistics: null,
    statsFilter: '10days'
  },
  mutations: {},
  actions: {
    loadStats({state,rootState}, period){
      state.statsFilter = period
      api.get('statistics', {params: {period: state.statsFilter}}).then((resp) =>{
        state.statistics = rootState.statusGroups.map((s) => {
          return [s.id, resp.data[s.id] ? resp.data[s.id] : 0]
        })
      })
    },
    async init({state, dispatch}) {
      let resp = await api.get('dashboard')
      state.totalOrders = resp.data.total
      dispatch('loadStats', '10days')
    }
  },
}

