import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Orders from '../views/Orders.vue'
import Tariffs from '../views/Tariffs.vue'
import Account from '../views/Account.vue'
import Home from '../layouts/Home.vue'
import LoginLayout from '../layouts/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      if (store.state.auth.loggedIn) {
        next()
      } else
        next({name: 'Login'})
    },

    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: async (to, from, next) => {
          await store.dispatch('dashboard/init')
          store.dispatch('orders/init')
          next()
        }
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        beforeEnter: async (to, from, next) => {
          await store.dispatch('orders/init')
          next()
        }

      }, {
        path: 'tariffs',
        name: 'Tariffs',
        component: Tariffs
      }, {
        path: 'account',
        name: 'Account',
        component: Account
      }, {
        path: '*',
        redirect: {name: 'Dashboard'}
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginLayout,
    beforeEnter: async (to, from, next) => {
      if (store.state.auth.loggedIn) {
        next({name: 'Dashboard'})
      } else
        next()
    },

    // children: [
    //   {
    //     path: 'login',
    //     name: 'Login',
    //     component: Login
    //   }
    // ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
