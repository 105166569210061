<template>
  <div class="home">
    <nav class="nav home__nav">
      <div class="nav__logo">
        <img src="@/assets/logo.png" alt="">
      </div>
      <router-link v-for="path in navItems"
                   :to="{name: path}" class="nav__item" tag="div">
        <div class="nav__icon">
          <img :src="$route.name == path ? iconsActive[path] : icons[path]" :alt="path">
        </div>
        <div class="nav__name">{{ path }}</div>
      </router-link>
    </nav>
    <div class="home__app">
      <div class="home__top">
        <div class="page">
          <div class="page__icon">
            <img :src="icons[$route.name]" :alt="$route.name">
          </div>
          <div class="page__name">
            {{ $route.name }}
          </div>
        </div>
        <User/>
      </div>

      <div class="home__main">
        <router-view/>
      </div>

    </div>

  </div>
</template>
<style lang="scss" scoped>

.page {
  display: flex;
  align-items: center;

  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #000000;
  }

  &__icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}
</style>
<style lang="scss">
.nav {
  &__logo {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    padding: 8px 0 8px 58px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: #BFBFBF;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.router-link-exact-active {
      color: #000000;
      font-weight: 600;
    }
  }

  &__icon {
    width: 64px;
    height: 64px;
    margin-right: 27px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {

    }

    .router-link-exact-active & {
      background-color: #FF720D;
      box-shadow: 0px 16px 31px rgba(255, 114, 13, 0.14);;
    }
  }
}

.home {
  height: 100%;
  display: flex;

  &__nav {
    width: 345px;
    background: #fff;
  }

  &__top {
    padding-left: 77px;
    padding-right: 100px;
    //height: 120px;
    flex: 0 0 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
  }

  &__app {
    flex: auto;
    flex-direction: column;
    display: flex;
    overflow-y: scroll;
  }

  &__main {
    background: #F9F9F9;
    padding: 30px 50px;
    flex: auto;

  }
}
</style>

<script>

import {mapActions, mapState} from 'vuex'
import Dashboard from '@/assets/nav/Dashboard.svg'
import DashboardActive from '@/assets/nav/DashboardActive.svg'
import Orders from '@/assets/nav/Orders.svg'
import OrdersActive from '@/assets/nav/OrdersActive.svg'
import Tariffs from '@/assets/nav/Tariffs.svg'
import Account from '@/assets/nav/Account.svg'
import User from '@/components/User'

export default {
  components:{ User},
  data() {
    return {
      navItems: [
        'Dashboard', 'Orders', 'Tariffs', 'Account'
      ],
      icons: {Dashboard, Orders, Tariffs, Account},
      iconsActive: {Dashboard: DashboardActive, Orders: OrdersActive, Tariffs, Account}

    }
  },

  computed: {
    ...mapState('auth', ['currentUser', 'loggedIn'])
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    performLogin() {
      this.login({creds: {email: this.email, password: this.password}})
    }
  }
}
</script>
