<template>
  <div class="home">
    <div class="home__app">
      <div class="home__main">
        <div class="login">
          <div class="login__form">
            <div class="login__logo">
              <img src="@/assets/logo.png" alt="">
            </div>
            <form @submit.prevent="performLogin">
              <label for="name">
                Email:
                <br>
                <input id="name"
                       autofocus
                       type="text" v-model="email"
                       :disabled="loading">
                <span v-if="error" class="error">Wrong email or password</span>
              </label>
              <label for="password">
                Password:
                <br>
                <input id="password"
                       type="password" v-model="password"
                       :disabled="loading">
              </label>
              <input type="submit" class="button"
                     :disabled="loading || !valid">
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
label {
  display: block;
  position: relative;
  .error{
    display: block;
    position: absolute;
    bottom: 12px;
    color: red;
    font-size: 14px;
  }
  input{
   border-color: red;
  }
}

input {
  width: 300px;
  height: 46px;
  line-height: 46px;
  font-size: 18px;
  border: 1px solid #EB6B04;
  margin-bottom: 35px;
  &:focus {
    filter: drop-shadow(0px 0px 5px rgba(255, 114, 13, 0.3));
    outline: none;
  }
}

.login {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__logo {
    text-align: center;
    margin-bottom: 35px;
  }
  &__form {
    background: white;
    padding: 30px;
  }
}
</style>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      error: false,
      loading: false,
      email: '',
      password: ''
    }
  },
  watch:{
    email(){
      this.error = false
    },
    password(){
      this.error = false
    }
  },
  computed: {
    ...mapState('auth', ['currentUser', 'loggedIn']),
    valid(){
      return !!this.email && !! this.password
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    async performLogin() {
      this.loading = true
      try{
        await this.login({creds: {email: this.email, password: this.password}})
      } catch (e) {
        this.password = null
        this.loading = false
        this.$nextTick(() => this.error = true)
      }

    }
  }
}
</script>
